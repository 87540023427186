import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioModel } from '../models/usuario.model';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl = environment.apiUrl;

  // private url = '/accounts:signInWithPassword?key=[API_KEY]';

  // private apikey = 'AIzaSyA86ko5rGvNWcQX-SHSaMnTacjcSrNr9eE';

  userToken: string;


  constructor( private http: HttpClient) {
    this.leerToken();
   }

  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('expira');
    localStorage.removeItem('info_user');
  }
  
  login(usuario: UsuarioModel){
    const authData = {
      ...usuario,
      returnSecureToken: true
    };

    return this.http.post(
      this.baseUrl+`api/login`,
      authData
    ).pipe(
      map( resp => {

        if(resp['resultado'] === 'error'){
          return resp;
        }
        
        this.guardarToken(resp['idToken']);
        localStorage.setItem('info_user', JSON.stringify(resp['resultado']));

        return resp;

      })
    );

  }

  private guardarToken( idToken: string){
    this.userToken = idToken;
    localStorage.setItem('token', idToken);

      let hoy = new Date();
      hoy.setSeconds(3600);

    localStorage.setItem('expira', hoy.getTime().toString());
   }
   
   leerToken(){
      if( localStorage.getItem('token')){
      this.userToken = localStorage.getItem('token');
   }else{
      this.userToken='';
   }
   
    return this.userToken;
   }

   estaAutenticado(): boolean {

    if(this.userToken.length < 2){

      return false;
    }

    const expira = Number(localStorage.getItem('expira'));
    const expiraDate = new Date();
    expiraDate.setTime(expira);

    console.log(expiraDate+" - "+new Date())

    if( expiraDate > new Date()){
      return true;

    }else{

      return false;
    }

      // return this.userToken.length > 2;
    }

}
