import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListaCarritoService } from 'src/app/services/lista-carrito.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  lista_carrito: any[] = [];
  info_user:any;

  constructor( 
    private auth: AuthService,
    private router:Router,
    private VerListaCarrito: ListaCarritoService
    ) { }

  ngOnInit() {
    // localStorage.clear();
    this.VerListaCarrito.VerListado();

    if(localStorage.getItem('info_user') != null){
      let value = localStorage.getItem('info_user');
      this.info_user = JSON.parse(value);

      // console.log(this.info_user);
    }

  }
  
  salir(){
    this.auth.logout();
    window.location.href = '/';
  }

  VerCarrito(){
    this.lista_carrito = this.VerListaCarrito.VerListado();
  }

  QuitarProducto(id){
    this.VerListaCarrito.RemoverProducto(id);
    this.lista_carrito = this.VerListaCarrito.VerListado();
  }

  buscar( termino:string ){
    // console.log(termino);
    this.router.navigate(['/busqueda',termino])
    }

}
