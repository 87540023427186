import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrecioProductoService {

  constructor() { }

  Obtener_preciofinal(tasa_interes: object, precio_final: number) {
  
    let precio:number;
    let tasa:number;
  
    if(localStorage.getItem('info_user')){

      let value = localStorage.getItem('info_user');
      let partes = JSON.parse(value);
      
      // console.log(partes['pagos']);

      if(partes['pagos'] == 'Semanal') {
      
      tasa = (tasa_interes['tasa_semanal'] / 100);
      precio = (precio_final * tasa) / (1-(1 + tasa)** -52);
      }
      else if(partes['pagos'] == 'Quincenal') {

      tasa = (tasa_interes['tasa_quincenal'] / 100);
      precio = (precio_final * tasa) / (1-(1 + tasa)** -24);
      }
      else if(partes['pagos'] == 'Mensual'){

      tasa = (tasa_interes['tasa_mensual'] / 100);
      precio = (precio_final * tasa) / (1-(1 + tasa)** -12);
      }
      else if(partes['pagos'] == 'Otro'){

      tasa = (tasa_interes['tasa_diaria'] / 100);
      precio = (precio_final * tasa) / (1-(1 + tasa)** -365/partes['otro']);
      }
      else{
        tasa = (tasa_interes['tasa_quincenal'] / 100);
        precio = (precio_final * tasa) / (1-(1 + tasa)** -24) ;
      }

    }else{
      tasa = (tasa_interes['tasa_quincenal'] / 100);
      precio = (precio_final * tasa) / (1-(1 + tasa)** -24) ;
    }

    return precio;
  
  }

  TotalCredito(tasa_interes:object){

    let precio:number = 0;

    for (let i = 0; i < localStorage.length; i++){
      let key = localStorage.key(i);
      let splitted = key.split("_", 1);

      if(splitted[0] === 'producto'){
        let value = localStorage.getItem(key);
        let partes = JSON.parse(value);

        precio = precio + this.Obtener_preciofinal(tasa_interes, (partes['precio_final'] * partes['cantidad']))
        
      }
    }

    return precio;

  }
  
}
