import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-carrito',
  templateUrl: './modal-carrito.component.html',
  styleUrls: ['./modal-carrito.component.scss']
})
export class ModalCarritoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

}
