import { Component, OnInit, Input  } from '@angular/core';
import { ProductoService } from 'src/app/services/producto.service';
import { ActivatedRoute } from '@angular/router';
import * as Fuse from 'fuse.js'

// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
// import { BusquedaComponent } from './busqueda.component';

// const routes: Routes = [
//   {
//     path: '',
//     component: BusquedaComponent
//   }
// ];

@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.scss']
})
export class BusquedaComponent implements OnInit{

  resultados:any [] = [];
  productos:any [] = [];
  tasa_interes:any [] = [];
  termino:string;
  categoria:number;
  num_arreglo:number;

  constructor(
    private activateRoute:ActivatedRoute,
    private busqueda:ProductoService
  ) {} 

    ngOnInit() {
        this.activateRoute.params.subscribe( params =>{

        this.busqueda.getBuscarProductos( params['termino'] )
        .subscribe( (data: any) => {

        // console.log(params['termino']);
        
        this.productos = data.info_productos;
        this.tasa_interes = data.tasa_interes;
        this.termino = params['termino'];

        var options = {
          keys: ['titulo', 'marca', 'modelo']
        }
        var fuse = new Fuse(this.productos, options)

        if(this.termino === ""){
          this.resultados = this.productos;
        }else{
          this.resultados = fuse.search(this.termino)
        }

         // this.resultados = this.marcas.filter

       // Aquí debes trabajar con lo que se guarde en this.resultado
       //});

        //this.resultados.filter(resultados =>{
          //return resultados.marcas.indexOf(this.busqueda) >= 0;});

        //console.log(this.resultados.filter);

        //this.resultados=marcas.filter(e => e.NombreExpediente.indexOf(termino) >= 0);



        // this.resultados.marcas;
        // console.log(this.resultados.marcas);

        //aqui vamos a desgranar el resultado
        // console.log(fuse.search('John'));

        });
        
      });
    }
}
