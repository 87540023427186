import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators, NgForm} from "@angular/forms";
import Swal from 'sweetalert2'
import { environment } from '../../../environments/environment';
import { DatosEnvioService } from 'src/app/services/datos-envio.service';

@Component({
  selector: 'app-form-editar-envios',
  templateUrl: './form-editar-envios.component.html',
  styleUrls: ['./form-editar-envios.component.scss']
})
export class FormEditarEnviosComponent implements OnInit {

  info_user:object;
  email_registro:string;
  envios: any[] = [];
  id_envio:number;
  errorMessage: string;
  editarEnvio: FormGroup;
  error_correo = false;
  submitted = false;
  finalizar = false;

  token: string = environment.token;

  constructor(
    private fb: FormBuilder, 
    private activateRoute:ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private datosenvio: DatosEnvioService
  ) { }

  ngOnInit() {
    this.nuevoEnvio();

    let value = localStorage.getItem('info_user');
    this.info_user = JSON.parse(value);

    this.email_registro = this.info_user['email'];


    this.activateRoute.params.subscribe( params =>{

      this.datosenvio.MostrarEnvio( params['id_envio'], this.email_registro, this.token)
      .subscribe( (data: any) => {

      this.editarEnvio.controls['id_envio'].setValue(data.info.id_envio);
      this.editarEnvio.controls['quien_recibe'].setValue(data.info.quien_recibe);
      this.editarEnvio.controls['telefono'].setValue(data.info.telefono);
      this.editarEnvio.controls['calle'].setValue(data.info.calle);
      this.editarEnvio.controls['numero_exterior'].setValue(data.info.numero_exterior);
      this.editarEnvio.controls['numero_interior'].setValue(data.info.numero_interior);
      this.editarEnvio.controls['colonia'].setValue(data.info.colonia);
      this.editarEnvio.controls['ciudad'].setValue(data.info.ciudad);
      this.editarEnvio.controls['codigo_postal'].setValue(data.info.codigo_postal);
      this.editarEnvio.controls['estado'].setValue(data.info.estado);
      this.editarEnvio.controls['comentario'].setValue(data.info.comentario);

      this.id_envio = params['id_envio'];

      });
      
    });
  }

  nuevoEnvio() {
    this.editarEnvio = this.fb.group({
      id_envio: [''],
      quien_recibe: ['', Validators.required],
      telefono: ['', [
        Validators.required, 
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), 
        Validators.maxLength(10)
      ]],
      calle: ['', Validators.required],
      numero_exterior: ['', Validators.required],
      numero_interior: [''],
      colonia: ['', Validators.required],
      ciudad: ['', Validators.required],
      codigo_postal: [
        '', [
          Validators.required, 
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(5), 
          Validators.maxLength(5)
        ]
      ],
      estado: ['', Validators.required],
      comentario: [''],
    });
  }

  get f_() { return this.editarEnvio.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.editarEnvio.invalid) {
      return;
    }

    Swal.fire({
      allowOutsideClick: false,
      html:'<strong>Procesando la información...</strong>',
    });
    Swal.showLoading();

    this.datosenvio.EditarEnvio(this.editarEnvio.value, this.email_registro, this.token)
    .subscribe( data => {
      Swal.close();
      // console.log(data);

      Swal.fire({
        type: 'success',
        title: 'Bien Hecho!',
        text: 'Datos Guardados Correctamente'
      });

      this.Regresar();

    },
      error => {
          this.errorMessage = error
      }
    );
  }

  Regresar(){
    this.activateRoute.params.subscribe( params =>{
  
      if(params['lugar'] === 'compras'){
        this.router.navigate(['/finalizar_compra/',params['id']]);
      }else{
        this.router.navigate(['/dashboard/direcciones']);
      }
  
    });
  }

}
