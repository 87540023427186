import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DatosCreditoService } from 'src/app/services/datos-credito.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-mi-credito',
  templateUrl: './mi-credito.component.html',
  styleUrls: ['./mi-credito.component.scss']
})
export class MiCreditoComponent implements OnInit {

  info_user:object;
  datos_credito: any[] = [];
  datos_pedido: any[] = [];
  datos_operacion:number;
  token:string;

  constructor(
    private activateRoute:ActivatedRoute,
    private http: HttpClient,
    private datos:DatosCreditoService,
  ) { }

  ngOnInit() {

    this.token = localStorage.getItem('token');
    let value = localStorage.getItem('info_user');
    this.info_user = JSON.parse(value);

    this.VerCredito();
  }

  VerCredito(){

    this.datos.MostrarCredito(this.info_user['email'],this.token)
    .subscribe( (data:any) => {
      this.datos_credito = data.info_credito;
      this.datos_pedido = data.info_pedidos;
      this.datos_operacion = data.credito_dis;
    });
  }

}
