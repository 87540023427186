import { Component, OnInit, Input } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-primer-paso',
  templateUrl: './primer-paso.component.html',
  styleUrls: ['./primer-paso.component.scss']
})
export class PrimerPasoComponent implements OnInit {

  baseUrl = environment.apiUrl;

  convenios: any[] = [];
  id_convenio:number = 0;
  siguiente_paso:boolean = false;

  constructor(private http: HttpClient) {
    
    this.http.get(this.baseUrl+'api/verconvenios')
    .subscribe( (resp:any) => {
      this.convenios = resp;
      console.log(resp);
      });

   }

   SeleccionarConvenio(id:number){
      this.id_convenio = id;
   }

   SiguientePaso(){
    if(this.id_convenio == 0){
      Swal.fire(
        '',
        'Es necesario seleccionar el lugar de trabajo',
        'warning'
      )
    }else{
      this.siguiente_paso = true;
    }
   }

  ngOnInit() {
  }

}
