import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { DatosFacturacionService } from 'src/app/services/datos-facturacion.service';

@Component({
  selector: 'app-facturacion',
  templateUrl: './facturacion.component.html',
  styleUrls: ['./facturacion.component.scss']
})
export class FacturacionComponent implements OnInit {

  info_user:object;
  datos_factura: any[] = [];
  ver_datos_factura: any[] = [];
  token:string;

  constructor(
    private activateRoute:ActivatedRoute,
    private http: HttpClient,
    private datos:DatosFacturacionService,
    ) {}

    ngOnInit() {

      this.token = localStorage.getItem('token');
      let value = localStorage.getItem('info_user');
      this.info_user = JSON.parse(value);
  
      this.VerRegistros();
  
    }
  
    VerRegistros(){
      this.datos.BuscarDatosFacturacion(this.info_user['email'],this.token)
      .subscribe( (data: any) => {
        this.datos_factura = data;
      });
    }
  
    Eliminar(id: number){
  
      Swal.fire({
        title: '¿Estás seguro de eliminar esta dirección de envió?',
        text: '',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, estoy seguro!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            allowOutsideClick: false,
            html:'<strong>eliminando...</strong>',
          });
          Swal.showLoading();
  
          this.datos.EliminarFactura(this.info_user['email'],this.token,id)
          .subscribe( (data: any) => {
            Swal.close();
      
            if(data != 'error'){
              Swal.fire({
                type: 'success',
                title: 'Listo!',
                text: 'Factura eliminada correctamente'
              });
      
            }else{
              Swal.fire({
                type: 'error',
                title: 'Algo salio mal!',
                text: 'Por favor vuelve a intentarlo mas tarde'
              });
            }
      
            this.VerRegistros();
      
          });
  
        }
      })
  
    }
  
    ActivarPredeterminado(id: number){
  
      Swal.fire({
        allowOutsideClick: false,
        html:'<strong>Guardando...</strong>',
      });
      Swal.showLoading();
  
      this.datos.FacturaPredeterminada(this.info_user['email'],this.token,id)
      .subscribe( (data: any) => {
        Swal.close();
  
        if(data != 'error'){
          Swal.fire({
            type: 'success',
            title: 'Listo!',
            text: 'Factura predeterminada activada'
          });
  
        }else{
          Swal.fire({
            type: 'error',
            title: 'Algo salio mal!',
            text: 'Por favor vuelve a intentarlo mas tarde'
          });
        }
  
        this.VerRegistros();
  
      });
  
    }

}
