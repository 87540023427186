import { Component, OnInit, Input, Output, HostListener, EventEmitter } from '@angular/core';
import { AgregarCarritoService } from 'src/app/services/agregar-carrito.service';
import { Observable } from 'rxjs';
import { ProductoService } from 'src/app/services/producto.service';
import { PrecioProductoService } from 'src/app/services/precio-producto.service';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss']
})
export class ProductoComponent implements OnInit {

  @Input() producto: any = {};
  @Input() tasa_interes: any = {};
  @Input() datos_carrito: any = {};

  tipo_pago:string;
  tasa: number;
  precio_final: any;

  constructor(
    private InfoProducto:AgregarCarritoService,
    private PrecioProducto:PrecioProductoService
  ) { }

  ngOnInit() {
    
     if(localStorage.getItem('info_user') != null){
      let value = localStorage.getItem('info_user');
      let partes = JSON.parse(value);

      if(partes['pagos']){
        this.tipo_pago = partes['pagos'];
      }else{
        this.tipo_pago = 'Quincenal';
      }
    }else{
      this.tipo_pago = 'Quincenal';
    }

    this.precio_final = this.PrecioProducto.Obtener_preciofinal(this.tasa_interes,this.producto.precio_final);

  // this.productos$ = this.ProductoService.getProductos$();
  // this.productos$.subscribe(productos => this.productos = productos);
  }

  AgregarAlCarrito(event: MouseEvent, producto: object, cantidad: number, precio: number) {
    this.InfoProducto.AgregarProducto(producto,cantidad,precio);

    this.datos_carrito = { 
      id_producto: producto["id_producto"], 
      nombre: producto["titulo"], 
      marca: producto['marcas']['nombre_marca'], 
      imagen: producto["imagen"], 
      cantidad: cantidad, 
      precio_parcial: precio, 
      precio_final: producto["precio_final"] 
    };

    // UIkit.modal("#modal-carrito").show();
  }

}
