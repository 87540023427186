import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ListaCarritoService {

  constructor() { }

  VerListado(){
    let lista_carrito= [];
    let contador = 0;

    for (let i = 0; i < localStorage.length; i++){
      let key = localStorage.key(i);

      let splitted = key.split("_", 1);

      if(splitted[0] === 'producto'){
        contador++;
        let value = localStorage.getItem(key);
        lista_carrito.push( JSON.parse(value));
      }
    }

    var myEle = document.getElementById("CantidadProductos");
    if(myEle){
      document.getElementById("CantidadProductos").innerHTML = ""+contador+"";
    }

    return lista_carrito;
  }

  RemoverProducto(id){
    localStorage.removeItem("producto_"+id);
  }

  EditarCantidad(valor: number, id:number){
    var existing = localStorage.getItem("producto_"+id);
    existing = existing ? JSON.parse(existing) : {};
    existing['cantidad'] = valor;
    localStorage.setItem("producto_"+id, JSON.stringify(existing));
  }

  CalcularPrecio(){
    let total = 0;

    for (let i = 0; i < localStorage.length; i++){
      let key = localStorage.key(i);

      let splitted = key.split("_", 1);

      if(splitted[0] === 'producto'){
        let value = localStorage.getItem(key);
        let partes = JSON.parse(value);

        total = total + (partes['cantidad'] * partes['precio_final']);
      }
      
    }

    return total;
  }

}
