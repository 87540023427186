import { Component, OnInit } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
// import { IndexComponent } from './../index/index.component';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  baseUrl = environment.apiUrl;

  productos: any[] = [];
  tasa_interes:any [] = [];
  banners: any[] = [];
  banner_1:string;
  banner_2:string;
  banner_3:string;


  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService
  ) { 

  this.spinner.show();

  window.onload = function() {  
    console.log("Hello World!");
  }; 
    
     this.http.get(this.baseUrl+'api/verproductos')
     .subscribe( (resp:any) => {

       this.productos = resp.info_productos;
       this.tasa_interes = resp.tasa_interes;
       
       });

       this.http.get(this.baseUrl+'api/verbanner')
      .subscribe( (ban:any) => {
        this.banners = ban;
        console.log(ban);
        });

        this.http.get(this.baseUrl+'api/banners_sec')
        .subscribe( (bansec:any) => {
          this.banner_1 = bansec[0].imagen;
          this.banner_2 = bansec[1].imagen;
          this.banner_3 = bansec[2].imagen;
          });

   }


  ngOnInit() {
    this.spinner.hide();
  }

}
