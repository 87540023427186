import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatosFacturacionService {

  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  BuscarDatosFacturacion (email: string, token:string){
    return this.http.post(this.baseUrl+`api/datos_factura`,{email, token});
  }

  
  FacturaPredeterminada (email: string, token:string, id_factura:number){
      return this.http.post(this.baseUrl+`api/factura_predeterminada`,{email, token, id_factura});
  }

  EliminarFactura (email: string, token:string, id_factura:number){
      return this.http.post(this.baseUrl+`api/eliminar_factura`,{email, token, id_factura});
  }

  MostrarFactura (id_factura: number, email: string, token:string){
    return this.http.post(this.baseUrl+`api/ver_facturas`,{id_factura, email, token});
  }

  EditarFactura (facturasEditar: object,email: string, token:string){
    return this.http.post(this.baseUrl+`api/editfacturas`,{facturasEditar, email, token});
  }

  postFacturas (facturasForm: object, email: string, token:string){
    return this.http.post(this.baseUrl+`api/guardar_facturas`,{facturasForm, email, token});
  }

}
