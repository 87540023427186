import { Component, OnInit } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LoginService } from 'src/app/services/login.service';
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { UsuarioModel } from '../../models/usuario.model';
import { AuthService } from '../../services/auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  // login: any[] = [];
  usuario: UsuarioModel = new UsuarioModel();
    // id_registro : number;
    recordarme = false;

  loginForm: FormGroup;
  submitted = false;
  error_login = false;
  true_login = false;

  constructor(
    private formB: FormBuilder, 
    private http: HttpClient, 
    private loginService: LoginService,
    private auth: AuthService, 
    private router: Router
  ) { 
  }

  ngOnInit() {
    this.nuevoLogin();

    if(localStorage.getItem('email')){
      this.usuario.email = localStorage.getItem('email');
      this.recordarme = true;
     }
  }

  async Recuperar(){
    const { value: email } = await Swal.fire({
      title: 'Ingresa el correo con el que te registraste',
      input: 'email',
      inputPlaceholder: 'Email'
    })
    
    if (email) {

      Swal.fire({
        allowOutsideClick: false,
        text: 'Enviando correo de recuperacion...'
      });
      
      Swal.showLoading();
      
      let datos_rec = {
        email: email,
       };

      this.loginService.RecuperarContra(datos_rec)
        .subscribe( (data: any) => {
          
          Swal.close();
        
          if(data['resultado'] === 'error email'){
            Swal.fire(
              'El correo no existe',
              'Verifica la información y vuelve a intentarlo',
              'error'
            )

            Swal.fire({
              title: 'El correo no existe',
              text: 'Verifica la información y vuelve a intentarlo',
              type: 'error',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#dd0053',
              cancelButtonText: 'Cancelar',
              confirmButtonText: 'Volver a intentar'
            }).then((result) => {
              if (result.value) {
                this.Recuperar()
              }
            })

          }else{
            Swal.fire(
              'Correo de recuperación enviado',
              'Verifica tu bandeja de entrada o en spam, puede tardar algunos minutos en llegar.',
              'success'
            )
          }  

        });

    }
  }

  login ( form: NgForm ){
    // console.log('Imprimir si el formulario es valido');

    if ( form.invalid ){ return; }

    Swal.fire({
      allowOutsideClick: false,
      text: 'Iniciando sesión...'
    });
    Swal.showLoading();

    this.auth.login( this.usuario  ).subscribe( resp => {
      
      // console.log(resp['resultado']);

      if(resp['resultado'] === 'error'){

        Swal.close();

        Swal.fire({
          type: 'error',
          title: 'Datos incorrectos',
          text: 'Por favor vuelta a intentarlo'
        });

        return false;

      }

      if(this.recordarme){
        localStorage.setItem('email', this.usuario.email);
       }

       window.location.href = '/';

    }, (err) => {
       
      // console.log(err.error.error.message);
      Swal.fire({
        type: 'error',
        title: 'Error al autenticar',
        text: err.error.error.message
      });
    
    });

    }

  nuevoLogin() {
    this.loginForm = this.formB.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  // onSubmit() {
  //   this.submitted = true;

  //   // stop here if form is invalid
  //   if (this.loginForm.invalid) {
  //     return;
  //   }

  //   this.loginService.postLogin(this.loginForm.value)
  //   .subscribe( data => {
  //     // console.log(data);

  //     if(data['resultado'] === "error"){
  //       this.error_login = true;
  //     }else{
  //       this.error_login = false;
  //       this.true_login = true;
  //     }

  //   }

  //   );
  // }

}
