import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { 
    // console.log('Servicio Listo!!!');
  }

  postLogin (id_registro: number){
    return this.http.post(this.baseUrl+`api/login`,id_registro);
  }

  RecuperarContra (datos_recuperar: object){
    return this.http.post(this.baseUrl+'api/recuperar',datos_recuperar);
  }

  verificar_token (token: string){
    return this.http.get(this.baseUrl+`api/verificar/${ token }`);
  }

  actualizar_pass (RecuperarForm: any, token:string){
    return this.http.post(this.baseUrl+`api/actualizar_pass`,{RecuperarForm, token});
  }

}
