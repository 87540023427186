import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { DatosFacturacionService } from 'src/app/services/datos-facturacion.service';
import {FormBuilder, FormGroup, Validators, NgForm} from "@angular/forms";
import Swal from 'sweetalert2'
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-form-editar-facturas',
  templateUrl: './form-editar-facturas.component.html'
  // styleUrls: ['./form-editar-facturas.component.scss']
})
export class FormEditarFacturasComponent implements OnInit {

  info_user:object;
  email_registro:string;
  facturas: any[] = [];
  id_factura:number;
  errorMessage: string;
  editarFactura: FormGroup;
  error_correo = false;
  submitted = false;
  finalizar = false;

  token: string = environment.token;

  constructor(
    private fb: FormBuilder, 
    private activateRoute:ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private datosfacturacion: DatosFacturacionService
  ) { }

  ngOnInit() {
    this.nuevaFacturacion();

    let value = localStorage.getItem('info_user');
    this.info_user = JSON.parse(value);

    this.email_registro = this.info_user['email'];


    this.activateRoute.params.subscribe( params =>{

      this.datosfacturacion.MostrarFactura( params['id_factura'], this.email_registro, this.token)
      .subscribe( (data: any) => {

      this.editarFactura.controls['id_factura'].setValue(data.info.id_factura);
      this.editarFactura.controls['rfc'].setValue(data.info.rfc);
      this.editarFactura.controls['razon_social'].setValue(data.info.razon_social);
      this.editarFactura.controls['telefono'].setValue(data.info.telefono);
      this.editarFactura.controls['calle'].setValue(data.info.calle);
      this.editarFactura.controls['numero_exterior'].setValue(data.info.numero_exterior);
      this.editarFactura.controls['numero_interior'].setValue(data.info.numero_interior);
      this.editarFactura.controls['colonia'].setValue(data.info.colonia);
      this.editarFactura.controls['ciudad'].setValue(data.info.ciudad);
      this.editarFactura.controls['codigo_postal'].setValue(data.info.codigo_postal);
      this.editarFactura.controls['estado'].setValue(data.info.estado);
      this.editarFactura.controls['email'].setValue(data.info.email);

      this.id_factura = params['id_factura'];

      });
      
    });
  }

  nuevaFacturacion() {
    this.editarFactura = this.fb.group({
      id_factura: [''],
      rfc: ['', [
        Validators.required, 
        Validators.minLength(13), 
        Validators.maxLength(13)
      ]],
      razon_social: ['', Validators.required],
      telefono: ['', [
        Validators.required, 
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), 
        Validators.maxLength(10)
      ]],
      calle: ['', Validators.required],
      numero_exterior: ['', Validators.required],
      numero_interior: [''],
      colonia: ['', Validators.required],
      ciudad: ['', Validators.required],
      codigo_postal: [
        '', [
          Validators.required, 
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(5), 
          Validators.maxLength(5)
        ]
      ],
      estado: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get f_() { return this.editarFactura.controls; }

  
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.editarFactura.invalid) {
      return;
    }

    Swal.fire({
      allowOutsideClick: false,
      html:'<strong>Procesando la información...</strong>',
    });
    Swal.showLoading();

    this.datosfacturacion.EditarFactura(this.editarFactura.value, this.email_registro, this.token)
    .subscribe( data => {
      Swal.close();
      // console.log(data);

      Swal.fire({
        type: 'success',
        title: 'Bien Hecho!',
        text: 'Datos Guardados Correctamente'
      });

      this.Regresar();

    },
      error => {
          this.errorMessage = error
      }
    );
  }

Regresar(){
  this.activateRoute.params.subscribe( params =>{

    if(params['lugar'] === 'compras'){
      this.router.navigate(['/finalizar_compra/',params['id']]);
    }else{
      this.router.navigate(['/dashboard/mis_datos_facturacion']);
    }

  });
}

}
