import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { IndexComponent } from './componentes/index/index.component';
import { BusquedaComponent } from './componentes/busqueda/busqueda.component';
import { ProductoComponent } from './componentes/producto/producto.component';
import { DetallesProductoComponent } from './componentes/detalles-producto/detalles-producto.component';
import { PrimerPasoComponent } from './componentes/solicitar_credito/primer-paso/primer-paso.component';
import { RegistroComponent } from './componentes/registro/registro.component';
import { CarritoComponent } from './componentes/carrito/carrito.component';
import { FinalizarCompraComponent } from './componentes/finalizar-compra/finalizar-compra.component';
import { LoginComponent } from './componentes/login/login.component';
import { AuthGuard } from './guard/auth.guard';
import { RecContraComponent } from './componentes/rec-contra/rec-contra.component';
import { FormEnviosComponent } from './componentes/form-envios/form-envios.component';
import { FormFacturasComponent } from './componentes/form-facturas/form-facturas.component';

// DASHBOARD CLIENTE
import { MiPerfilComponent } from './componentes/dashboard/mi-perfil/mi-perfil.component';
import { MisPedidosComponent } from './componentes/dashboard/mis-pedidos/mis-pedidos.component';
import { MiCreditoComponent } from './componentes/dashboard/mi-credito/mi-credito.component';
import { DireccionesComponent } from './componentes/dashboard/direcciones/direcciones.component';
import { FacturacionComponent } from './componentes/dashboard/facturacion/facturacion.component';
import { FormEditarFacturasComponent } from './componentes/form-editar-facturas/form-editar-facturas.component';
import { FormEditarEnviosComponent } from './componentes/form-editar-envios/form-editar-envios.component';

// const routes: Routes = [
//   {
//     path: '',
//     component: IndexComponent,
//     children: [
//      {
//        path: 'busqueda',
//        loadChildren: './componentes/busqueda/busqueda.component'
//      },
//      {
//        path: 'producto',
//        loadChildren: './componentes/producto/producto.component'
//      }
//     ]
//   },
//   {  path: '**', redirectTo: 'battle' },
// ];

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  // {
  //   path: '', 
  //   loadChildren: () => import('./componentes/index/index.component').then(mod => mod.IndexComponent)
  // },
  { path: '', component: IndexComponent },
  { path: 'busqueda', redirectTo: 'busqueda/'},
  { path: 'busqueda/:termino', component: BusquedaComponent },
  { path: 'producto', component: ProductoComponent },
  { path: 'detalles_producto/:id', component: DetallesProductoComponent },
  { path: 'registro_credito', component: PrimerPasoComponent },
  { path: 'registro', component: RegistroComponent },
  { path: 'carrito', component: CarritoComponent },
  // { path: 'finalizar_compra/:tipo_pago', component: FinalizarCompraComponent, canActivate:[AuthGuard] },
  { path: 'finalizar_compra/', component: FinalizarCompraComponent },
  { path: 'finalizar_compra/:tipo_pago', component: FinalizarCompraComponent },
  { path: 'login', component: LoginComponent },
  { path: 'rec_pass/:token', component: RecContraComponent },
  { path: 'agregar_direccion/:lugar/:id', component: FormEnviosComponent },
  { path: 'datos_facturas/:lugar/:id', component: FormFacturasComponent },
  { path: 'ver_facturas/:id_factura', component: FormEditarFacturasComponent },
  { path: 'ver_envios/:id_envio', component: FormEditarEnviosComponent },

  // RUTAS DASHBOARD CLIENTES
  { path: 'dashboard/mi_perfil', component: MiPerfilComponent },
  { path: 'dashboard/mis_pedidos', component: MisPedidosComponent },
  { path: 'dashboard/mi_credito', component: MiCreditoComponent },
  { path: 'dashboard/direcciones', component: DireccionesComponent },
  { path: 'dashboard/mis_datos_facturacion', component: FacturacionComponent },
];


const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes,routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
