import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatosRegistroService {

  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  DatosRegistro (email: string, token:string){
    return this.http.post(this.baseUrl+`api/verregistro`,{email, token});
  }


}
