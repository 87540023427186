import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatosCreditoService {

  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  MostrarCredito (email: string, token:string){
    return this.http.post(this.baseUrl+`api/VerCredito`,{email, token});
  }
}
