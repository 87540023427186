import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './componentes/menu/menu.component';
import { IndexComponent } from './componentes/index/index.component';
import { ProductoComponent } from './componentes/producto/producto.component';
import { FooterComponent } from './componentes/footer/footer.component';
import { BusquedaComponent } from './componentes/busqueda/busqueda.component';
import { DetallesProductoComponent } from './componentes/detalles-producto/detalles-producto.component';
import { PrimerPasoComponent } from './componentes/solicitar_credito/primer-paso/primer-paso.component';
import { FooterLoginComponent } from './componentes/solicitar_credito/footer-login/footer-login.component';
import { SegundoPasoComponent } from './componentes/solicitar_credito/segundo-paso/segundo-paso.component';
import { TercerPasoComponent } from './componentes/solicitar_credito/tercer-paso/tercer-paso.component';
import { RegistroComponent } from './componentes/registro/registro.component';
import { CarritoComponent } from './componentes/carrito/carrito.component';
import { ModalCarritoComponent } from './componentes/modal-carrito/modal-carrito.component';
import { FinalizarCompraComponent } from './componentes/finalizar-compra/finalizar-compra.component';
import { LoginComponent } from './componentes/login/login.component';
import { RecContraComponent } from './componentes/rec-contra/rec-contra.component';
import { FormEnviosComponent } from './componentes/form-envios/form-envios.component';
import { FormFacturasComponent } from './componentes/form-facturas/form-facturas.component';
import { DireccionesComponent } from './componentes/dashboard/direcciones/direcciones.component';
import { FacturacionComponent } from './componentes/dashboard/facturacion/facturacion.component';
import { MisPedidosComponent } from './componentes/dashboard/mis-pedidos/mis-pedidos.component';
import { MiCreditoComponent } from './componentes/dashboard/mi-credito/mi-credito.component';
import { MiPerfilComponent } from './componentes/dashboard/mi-perfil/mi-perfil.component';
import { FormEditarFacturasComponent } from './componentes/form-editar-facturas/form-editar-facturas.component';
import { FormEditarEnviosComponent } from './componentes/form-editar-envios/form-editar-envios.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    IndexComponent,
    ProductoComponent,
    FooterComponent,
    BusquedaComponent,
    DetallesProductoComponent,
    PrimerPasoComponent,
    FooterLoginComponent,
    SegundoPasoComponent,
    TercerPasoComponent,
    RegistroComponent,
    LoginComponent,
    CarritoComponent,
    ModalCarritoComponent,
    FinalizarCompraComponent,
    RecContraComponent,
    FormEnviosComponent,
    FormFacturasComponent,
    DireccionesComponent,
    FacturacionComponent,
    MisPedidosComponent,
    MiCreditoComponent,
    MiPerfilComponent,
    FormEditarFacturasComponent,
    FormEditarEnviosComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
