import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DatosEnvioService } from 'src/app/services/datos-envio.service';
import { DatosFacturacionService } from 'src/app/services/datos-facturacion.service';
import { GuardarPedidoService } from 'src/app/services/guardar-pedido.service';
import { ListaCarritoService } from 'src/app/services/lista-carrito.service';
import { PrecioProductoService } from 'src/app/services/precio-producto.service';
import { environment } from '../../../environments/environment';

import * as Fuse from 'fuse.js'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-finalizar-compra',
  templateUrl: './finalizar-compra.component.html',
  styleUrls: ['./finalizar-compra.component.scss']
})
export class FinalizarCompraComponent implements OnInit {

  baseUrl = environment.apiUrl;
  token:string;

  info_user:object;
  tipo_pago: string;
  numero_tipo_pago: string;
  tiempo_pago: string;

  datos_envio: any[] = [];
  ver_datos_envio: any[] = [];

  datos_facturas: any[] = [];
  ver_datos_factura: any[] = [];

  lista_carrito: any[] = [];
  total_pagar: number;

  finalizado:boolean = false;
  cargar:boolean = false;

  constructor(
    private activateRoute:ActivatedRoute,
    private http: HttpClient,
    private datos:DatosEnvioService,
    private datos_fac:DatosFacturacionService,
    private GuardarPedido:GuardarPedidoService,
    private PrecioProducto:PrecioProductoService,
    private VerListaCarrito: ListaCarritoService
    ) {}

  ngOnInit() {

    this.activateRoute.params.subscribe( params =>{
      
      this.numero_tipo_pago = params['tipo_pago'];
      
      if(params['tipo_pago'] === '1'){
        this.tipo_pago = "de contado";
        this.total_pagar = this.VerListaCarrito.CalcularPrecio();
      }else{
        this.tipo_pago = "Crédito aprobadito";

        this.http.get(this.baseUrl+'api/verproductos')
        .subscribe( (resp:any) => {
          this.total_pagar = this.PrecioProducto.TotalCredito(resp.tasa_interes);
        });

        let value = localStorage.getItem('info_user');
        let partes = JSON.parse(value);

        if(partes['pagos'] === "Mensual"){
          this.tiempo_pago = "mensuales";
        }else if(partes['pagos'] == "Quincenal"){
          this.tiempo_pago = "quincenales";
        }else{
          this.tiempo_pago = "semanales";
        }

      }
    });

    this.lista_carrito = this.VerListaCarrito.VerListado();

    this.token = localStorage.getItem('token');
    let value = localStorage.getItem('info_user');
    this.info_user = JSON.parse(value);

    this.datos.BuscarDatosEnvio(this.info_user['email'],this.token)
    .subscribe( (data: any) => {
      this.datos_envio = data;
    });

    this.datos_fac.BuscarDatosFacturacion(this.info_user['email'],this.token)
    .subscribe( (data: any) => {
      this.datos_facturas = data;
    });

  }

  RealizarPedido(){

    if(this.ver_datos_envio.length === 0){
      Swal.fire(
        '',
        'No has seleccionado una dirección de envío',
        'warning'
      )
    }else{

      this.cargar = true;

      let datos_compra = {
        email: this.info_user['email'],
        total_pagar: this.total_pagar,
        tipo_de_pago: this.tipo_pago,
        datos_envio: this.ver_datos_envio,
        datos_factura: this.ver_datos_factura,
        lista_productos: this.lista_carrito
       };

       this.GuardarPedido.GuardarPedido(datos_compra)
        .subscribe( (data: any) => {
          
          this.finalizado = true;

          for (let i = 0; i < localStorage.length; i++){
            let key = localStorage.key(i);
      
            let splitted = key.split("_", 1);
      
            if(splitted[0] === 'producto'){
              localStorage.removeItem(key);
            }
          }

        });

    }
  }

  VerDatosEnvio(id:string){

    if(id === ''){
      this.ver_datos_envio = [];
      return false
    }

    var options = {
      threshold: 0.0,
      keys: ['id_envio']
    }
    var fuse = new Fuse(this.datos_envio, options)

    let resultado = fuse.search(id)
    this.ver_datos_envio = resultado[0]

  }

  VerDatosFactura(id:string){

    if(id === ''){
      this.ver_datos_factura = [];
      return false
    }

    var options = {
      threshold: 0.0,
      keys: ['id_factura']
    }
    var fuse = new Fuse(this.datos_facturas, options)

    let resultado = fuse.search(id)
    this.ver_datos_factura = resultado[0]

  }

}
