import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MiPerfilService {

  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  DatosPerfil (enviosForm: object,email: string, token:string){
    return this.http.post(this.baseUrl+`api/miperfil`,{enviosForm,email, token});
  }

}
