import { Component, OnInit } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ProductoService } from 'src/app/services/producto.service';
import { FormBuilder, FormGroup, Validators, NgForm} from "@angular/forms";
import { Router} from "@angular/router";
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import { DatosRegistroService } from 'src/app/services/datos-registro.service';
import { MiPerfilService } from 'src/app/services/mi-perfil.service';


@Component({
  selector: 'app-mi-perfil',
  templateUrl: './mi-perfil.component.html',
  styleUrls: ['./mi-perfil.component.scss']
})
export class MiPerfilComponent implements OnInit {

  info_user:object;
  datos_registro: any[] = [];
  token:string;
  baseUrl = environment.apiUrl;
  errorMessage: string;
  tipo: string;

  registroForm: FormGroup;
  submitted = false;
  finalizar = false;
  error_correo = false;

  constructor(
    private activateRoute:ActivatedRoute,
    private http: HttpClient,
    private datos:DatosRegistroService,
    private perfil:MiPerfilService,
    private fb: FormBuilder, 
    private router: Router, 
    private productoService: ProductoService
    ) { 

  }

  ngOnInit() {

    this.token = localStorage.getItem('token');
    let value = localStorage.getItem('info_user');
    this.info_user = JSON.parse(value);

    this.datos.DatosRegistro(this.info_user['email'],this.token)
    .subscribe( (data: any) => {
      this.datos_registro = data;

      this.registroForm.controls['nombre'].setValue(data.nombre);
      this.registroForm.controls['a_paterno'].setValue(data.a_paterno);
      this.registroForm.controls['a_materno'].setValue(data.a_materno);
      this.registroForm.controls['fecha_nac'].setValue(data.fecha_nac);
      this.registroForm.controls['sexo'].setValue(data.sexo);
      this.registroForm.controls['telefono'].setValue(data.telefono);
      this.registroForm.controls['email'].setValue(data.email);
      // this.registroForm.controls['password'].setValue(data.password);


      this.tipo = data.sexo;

    });

    this.nuevoRegistro();

  }

  GuardarSexo(sexo:string){
    this.tipo = sexo;
    this.registroForm.controls['sexo'].setValue(sexo);
  }

  nuevoRegistro() {
  //   this.registroForm = this.fb.group({
  //     nombre: [''],
  //     a_paterno: [''],
  //     a_materno: [''],
  //     fecha_nac: [''],
  //     sexo: [''],
  //     telefono: [''],
  //     email: [''],
  //     password: [''],
  //   });

    this.registroForm = this.fb.group({
      nombre: ['', Validators.required],
      a_paterno: ['', Validators.required],
      a_materno: [''],
      fecha_nac: ['', Validators.required],
      sexo: [''],
      telefono: ['', [
        Validators.required, 
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), 
        Validators.maxLength(10)
      ]],
      email: ['', [Validators.required, Validators.email]],
      password: [''],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registroForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registroForm.invalid) {
      return;
    }

    Swal.fire({
      allowOutsideClick: false,
      html:'<strong>Procesando la información...</strong>',
    });
    Swal.showLoading();

    this.perfil.DatosPerfil(this.registroForm.value, this.info_user['email'], this.token)
    .subscribe( data => {
      
      Swal.close();
      
      if(data['resultado'] === "error email"){
        Swal.fire(
          'El correo ya ha sido registrado',
          'Puedes recuperar la contraseña o intentar con un correo diferente',
          'warning'
        )
      }else{
        Swal.fire(
          'Guardado correcto!',
          'Guardado',
          'success'
        )
      }
    },
      error => {
          this.errorMessage = error
      }
    );
    // console.log('registroForm' , this.registroForm.value);
  }
  

}
