import { Component } from '@angular/core';
import { first } from 'rxjs/operators';

import { NgxSpinnerService } from "ngx-spinner";
import { Router, NavigationStart  }  from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading = false;

  mostrar: boolean = false;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService
) {

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {

        var str = event['url']; 
        var splitted = str.split("/", 2); 
        if (
          splitted[1] == 'registro' ||
          splitted[1] == 'finalizar_compra' ||
          splitted[1] == 'rec_pass' ||
          splitted[1] == 'registro_credito' ||
          splitted[1] == 'finalizar_compra' ||
          splitted[1] == 'agregar_direccion' ||
          splitted[1] == 'datos_facturas' ||
          splitted[1] == 'login' 
          ) {
          this.mostrar = false;
        } else {
          this.mostrar = true;
        }
      }
    });

}

  ngOnInit() {

    /** spinner starts on init */
  //   this.spinner.show();

  // window.onload = function() {  
  //   console.log("Hello World!");
  //  };  

    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 2000);
     
 }

 ngAfterViewInit() {
  // this.spinner.hide();
  }

}
