import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm, CheckboxControlValueAccessor } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-rec-contra',
  templateUrl: './rec-contra.component.html',
  styleUrls: ['./rec-contra.component.scss']
})
export class RecContraComponent implements OnInit {

  // resultados:any [] = [];
  resultados:number;
  token:string;
  RecuperarForm: FormGroup;
  submitted = false;

  finalizar = false;
  error_contra = false;

  constructor(
    private fb: FormBuilder,
    private Verificar_Token: LoginService,
    private actualizar_pass: LoginService,
    private activateRoute:ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit() {

    this.ChecarCampos();

    this.activateRoute.params.subscribe( params =>{

      this.Verificar_Token.verificar_token( params['token'] )
      .subscribe( (data: any) => {

      if(data.resultado === 'error'){
        this.router.navigateByUrl('/login')
      }

      // this.RecuperarForm.controls['token'].setValue(params['token']);

      this.token = params['token'];

      });
    });

  }

  ChecarPass(){
    if(this.submitted){
      if(this.RecuperarForm.value.password != this.RecuperarForm.value.password2){
        this.error_contra = true;
      }else{
        this.error_contra = false;
      }
    }
  }

  get f() { return this.RecuperarForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.RecuperarForm.invalid) {
      return;
    }

    if(this.RecuperarForm.value.password != this.RecuperarForm.value.password2){
      this.error_contra = true;
      return;
    }else{
      this.error_contra = false;
    }

    Swal.fire({
      allowOutsideClick: false,
      html:'<strong>Procesando la información...</strong>',
    });
    Swal.showLoading();

    this.actualizar_pass.actualizar_pass(this.RecuperarForm.value, this.token)
    .subscribe( data => {

      // Swal.close();

      if(data['resultado'] === "error"){
        Swal.fire(
          'Solicitud rechazada',
          'Por recarga la pagina y vuelve a intentarlo',
          'error'
        )
      }else{
        
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Contraseña actualizada',
          text: "Redireccionando al login…",
          showConfirmButton: false,
          timer: 2000
        })
    
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 2000);
    

      }
      // console.log(data);
      this.router.navigate(['/login']);
    });

    // --tercer api 
    // VAMOS A PONER UN MENSAJE DE BORRAR VALIDANDO LA CONTRA. UN ALERT DE VERIFICANDO
 
  }

  ChecarCampos() {
    this.RecuperarForm = this.fb.group({
      password: ['', [
        Validators.required,
        Validators.minLength(6)
      ]],
      password2: ['', [
        Validators.required,
        Validators.minLength(6)
      ]],
    });

  }
}


