import { Injectable } from '@angular/core';
import { formatCurrency } from '@angular/common';
declare var UIkit: any;
import { from } from 'rxjs';

declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class AgregarCarritoService {

  constructor() { }

  AgregarProducto(producto: object, cantidad: number, precio: number) {
    event.preventDefault();
    event.stopPropagation();

    if(cantidad == null){ cantidad = 1; }

    // console.log(producto);

    let myObj = { 
      id_producto: producto["id_producto"],
      nombre: producto["titulo"], 
      marca: producto['marcas']['nombre_marca'], 
      imagen: producto["imagen"], 
      cantidad: cantidad, 
      precio_parcial: precio, 
      precio_final: producto["precio_final"] 
    };

    localStorage.setItem("producto_"+producto["id_producto"], JSON.stringify(myObj));

    let contador = 0;

    for (let i = 0; i < localStorage.length; i++){
      let key = localStorage.key(i);
      let splitted = key.split("_", 1);
      if(splitted[0] === 'producto'){
        contador++;
      }
    }

    document.getElementById("CantidadProductos").innerHTML = ""+contador+"";

    document.getElementById("Carrito_imagen").setAttribute( 'src', 'https://api.aprobadito.com/img_productos/'+producto["imagen"]+'');
    document.getElementById("Carrito_titulo").innerHTML = ""+producto["titulo"]+"";
    document.getElementById("Carrito_marca").innerHTML = ""+producto['marcas']['nombre_marca']+"";
    document.getElementById("Carrito_cantidad").innerHTML = ""+cantidad+"";

    var numeral = require('numeral');

    document.getElementById("Carrito_precio").innerHTML = "$"+numeral(producto["precio_final"] * cantidad).format('0,0') +"";

    UIkit.modal("#modal-carrito").show();

    // alert("Articulo: "+producto["titulo"]+" Cantidad: "+ cantidad + " Precio: "+precio.toFixed(2));

    return false;
  }

}
