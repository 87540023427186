import { Component, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms"
import { ActivatedRoute } from '@angular/router';
import { ProductoService } from 'src/app/services/producto.service';
import { AgregarCarritoService } from 'src/app/services/agregar-carrito.service';
import { PrecioProductoService } from 'src/app/services/precio-producto.service';

@Component({
  selector: 'app-detalles-producto',
  templateUrl: './detalles-producto.component.html',
  styleUrls: ['./detalles-producto.component.scss']
})
export class DetallesProductoComponent implements OnInit {

detalles: any[] = [];
NombreMarca: string;

tipo_pago:string;
precio_final: any;

cantidad:FormControl = new FormControl();

  constructor(
    private InfoProducto:AgregarCarritoService,
    private activateRoute: ActivatedRoute, 
    private busqueda:ProductoService,
    private PrecioProducto:PrecioProductoService
    ) {  }

   ngOnInit() {
    this.activateRoute.params.subscribe( params =>{

      // console.log( params['id'] );

      this.busqueda.getDetallesProducto( params['id'] )
      .subscribe( (data: any) => {

      this.NombreMarca = data.info_productos.marcas.nombre_marca;
      this.detalles = data.info_productos;

      if(localStorage.getItem('info_user') != null){
        let value = localStorage.getItem('info_user');
        let partes = JSON.parse(value);
  
        this.tipo_pago = partes['pagos'];

        if(partes['pagos'] === "Mensual"){
          this.tipo_pago = "12 meses";
        }else if(partes['pagos'] == "Quincenal"){
          this.tipo_pago = "24 quincenas";
        }else{
          this.tipo_pago = "52 semanas";
        }


      }else{
        this.tipo_pago = '24 quincenas';
      }
  
      this.precio_final = this.PrecioProducto.Obtener_preciofinal(data.tasa_interes,data.info_productos.precio_final);

      });
      
    });
  }

  AgregarAlCarrito(event: MouseEvent, producto: object, cantidad: number, precio:number) {
    this.InfoProducto.AgregarProducto(producto,cantidad,precio);
  }

}
