import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatosEnvioService {

  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

 BuscarDatosEnvio (email: string, token:string){
    return this.http.post(this.baseUrl+`api/datos_envio`,{email, token});
  }

  DireccionPredeterminada (email: string, token:string, id_envio:number){
     return this.http.post(this.baseUrl+`api/direccion_predeterminada`,{email, token, id_envio});
  }

  EliminarDireccion (email: string, token:string, id_envio:number){
     return this.http.post(this.baseUrl+`api/eliminar_direccion`,{email, token, id_envio});
  }

  MostrarEnvio (id_envio: number, email: string, token:string){
    return this.http.post(this.baseUrl+`api/ver_envios`,{id_envio, email, token});
  }

  EditarEnvio (enviosEditar: object,email: string, token:string){
    return this.http.post(this.baseUrl+`api/edit_envios`,{enviosEditar, email, token});
  }

  postEnvio (enviosForm: object, email: string, token:string){
    return this.http.post(this.baseUrl+`api/guardar_envios`,{enviosForm, email, token});
  }

}
