import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProductoService {

  baseUrl = environment.apiUrl;

  private productos$ = new Subject<any[]>();
  productos: any;

  agregarProducto(producto: any) {
    this.productos.push(producto);
    this.productos$.next(this.productos);
  }

  getProductos$(): Observable<any[]> {
    return this.productos$.asObservable();
  }

  constructor(private http: HttpClient) { 
    console.log('Servicio Listo!!!');
  }

  getProductos() {
     return this.http.get(this.baseUrl+'api/verproductos');
  }

  getBuscarProductos(termino: string) {
    return this.http.get(this.baseUrl+`api/verproductos?buscar=${ termino }`);
 }

 getDetallesProducto(id_producto: number) {
  return this.http.get(this.baseUrl+`api/verdetalles/${ id_producto }`);
 }

 getBannerProducto() {
  return this.http.get(this.baseUrl+`api/verbanner`);
 }

 getConvenios() {
  return this.http.get(this.baseUrl+`api/verconvenios`);
 }

postRegistro (id_registro: number){
    return this.http.post(this.baseUrl+`api/registro`,id_registro);
}

}

