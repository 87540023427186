import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { GuardarPedidoService } from 'src/app/services/guardar-pedido.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-mis-pedidos',
  templateUrl: './mis-pedidos.component.html',
  styleUrls: ['./mis-pedidos.component.scss']
})
export class MisPedidosComponent implements OnInit {

  info_user:object;
  datos_pedido: any[] = [];
  ver_datos_pedidos: any[] = [];
  token:string;

  constructor(
    private activateRoute:ActivatedRoute,
    private http: HttpClient,
    private datos:GuardarPedidoService,
  ) { }

  ngOnInit() {

    this.token = localStorage.getItem('token');
    let value = localStorage.getItem('info_user');
    this.info_user = JSON.parse(value);

    this.VerPedidos();
  }

  
  VerPedidos(){

    this.datos.MostrarPedido(this.info_user['email'],this.token)
    .subscribe( (data: any) => {
      this.datos_pedido = data.info;

      console.log(data.info);

    });
  }

}
