import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { DatosEnvioService } from 'src/app/services/datos-envio.service';
import {FormBuilder, FormGroup, Validators, NgForm} from "@angular/forms";
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-form-envios',
  templateUrl: './form-envios.component.html',
  styleUrls: ['./form-envios.component.scss']
})
export class FormEnviosComponent implements OnInit {

  info_user:object;
  email_registro:string;
  email:string;
  envios: any[] = [];
  errorMessage: string;
  enviosForm: FormGroup;
  error_correo = false;
  submitted = false;
  finalizar = false;
  
  token: string = environment.token;

  constructor(
    private fb: FormBuilder, 
    private activateRoute:ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private datosenvios: DatosEnvioService
    ) { 

      // this.http.get('/api/envios')
      // .subscribe( (resp:any) => {
      // this.envios = resp;
      // console.log(resp);
      // });

    }

    ngOnInit() {
      this.nuevoEnvio();

      let value = localStorage.getItem('info_user');
      this.info_user = JSON.parse(value);

      this.email_registro = this.info_user['email'];

    }
  
    nuevoEnvio() {
      this.enviosForm = this.fb.group({
        quien_recibe: ['', Validators.required],
        telefono: ['', [
          Validators.required, 
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(10), 
          Validators.maxLength(10)
        ]],
        calle: ['', Validators.required],
        numero_exterior: ['', Validators.required],
        numero_interior: [''],
        colonia: ['', Validators.required],
        ciudad: ['', Validators.required],
        codigo_postal: [
          '', [
            Validators.required, 
            Validators.pattern("^[0-9]*$"),
            Validators.minLength(5), 
            Validators.maxLength(5)
          ]
        ],
        estado: ['', Validators.required],
        comentario: [''],
      });
    }
  
    // convenience getter for easy access to form fields
    get f_() { return this.enviosForm.controls; }

    onSubmit() {
      this.submitted = true;
  
      // stop here if form is invalid
      if (this.enviosForm.invalid) {
        return;
      }
  
      Swal.fire({
        allowOutsideClick: false,
        html:'<strong>Procesando la información...</strong>',
      });
      Swal.showLoading();

      this.datosenvios.postEnvio(this.enviosForm.value, this.email_registro, this.token)
      .subscribe( data => {
        Swal.close();
        // console.log(data);

        Swal.fire({
          type: 'success',
          title: 'Bien Hecho!',
          text: 'Datos Guardados Correctamente'
        });

        this.Regresar();

      },
        error => {
            this.errorMessage = error
        }
      );
    }

  Regresar(){
    this.activateRoute.params.subscribe( params =>{

      if(params['lugar'] === 'compras'){
        this.router.navigate(['/finalizar_compra/',params['id']]);
      }else{
        this.router.navigate(['/dashboard/direcciones']);
      }

    });
  }

}
