import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GuardarPedidoService {

  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  GuardarPedido (datos_compra: object){
    return this.http.post(this.baseUrl+`api/GuardarPedido`,datos_compra);
  }

  MostrarPedido (email: string, token:string){
    return this.http.post(this.baseUrl+`api/VerPedido`,{email, token});
  }

}
