import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var UIkit: any;
import Swal from 'sweetalert2';
import { ListaCarritoService } from 'src/app/services/lista-carrito.service';
import { PrecioProductoService } from 'src/app/services/precio-producto.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss']
})
export class CarritoComponent implements OnInit {

  baseUrl = environment.apiUrl;

  lista_carrito: any[] = [];
  total_pagar: number;
  opcion_pago:number;
  tipo_pago:string;
  total_credito:number;
  tiempo:string;
  session:boolean = false;
  MostrarCarga:boolean = false;
  
  precio_credito: number;

  constructor(
    private router: Router,
    private VerListaCarrito: ListaCarritoService,
    private PrecioProducto:PrecioProductoService,
    private http: HttpClient
    ) { }

  ngOnInit() {

    if(localStorage.getItem('info_user') != null){
      this.session = true;

      let value = localStorage.getItem('info_user');
      let partes = JSON.parse(value);

      this.tipo_pago = partes['pagos'];

      if(partes['pagos'] === "Mensual"){
        this.tiempo = "12";
        this.tipo_pago = "mensuales";
      }else if(partes['pagos'] == "Quincenal"){
        this.tiempo = "24";
        this.tipo_pago = "quincenales";
      }else{
        this.tiempo = "52";
        this.tipo_pago = "semanales";
      }

      this.http.get(this.baseUrl+'api/VerCredito/'+partes['email'])
      .subscribe( (resp:any) => {
        this.total_credito = resp;
      });

    }else{
      this.tipo_pago = 'quincenales';
    }

    UIkit.drop("#ListaCarrito").hide();
    this.VerCarrito();
  }

  SeleccionarPago(){

    if(this.total_credito > this.total_pagar){
      this.opcion_pago = 2
    }
  }

  VerCarrito(){
    this.lista_carrito = this.VerListaCarrito.VerListado();
    this.total_pagar = this.VerListaCarrito.CalcularPrecio();
    
    this.CalcularPrecioCredito();
  }

  CalcularPrecioCredito(){

    this.MostrarCarga = true;

    this.http.get(this.baseUrl+'api/verproductos')
    .subscribe( (resp:any) => {
      this.precio_credito = this.PrecioProducto.TotalCredito(resp.tasa_interes);

      this.MostrarCarga = false;
    });

  }

  QuitarProducto(id){
    this.VerListaCarrito.RemoverProducto(id);
    this.VerCarrito();
  }

  CambiarCantidad(event: any, id:number){
    this.VerListaCarrito.EditarCantidad(event.target.value, id);
    this.VerCarrito();
  }

  Check(){

    if(this.opcion_pago === 1 || this.opcion_pago== 2){
      this.router.navigate(['/finalizar_compra',this.opcion_pago]);
    }else{
      Swal.fire({
          title: '',
          text: 'No has seleccionado una forma de pago',
          type: 'warning',
          confirmButtonText: 'Enterado'
        })
      }
    }

}
